import React from "react";
import PropTypes from "prop-types";

import SEO from "./Seo";

const Head = ({ pageMeta, noIndex = false }) => {
  return (
    <>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      {noIndex && <meta name="robots" content="noindex" />}
      <link
        href="https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,500;0,700;1,500&family=DM+Serif+Display&family=Inter:wght@300&display=swap"
        rel="stylesheet"
      ></link>
      <link rel="icon" href="../../images/favicon.ico" />
      <SEO title={pageMeta?.title} description={pageMeta?.description} />;
    </>
  );
};

export default Head;

Head.propTypes = {
  pageMeta: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  noIndex: PropTypes.bool,
};
