import React from "react";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";

const SEO = ({ title, description, pathname, children }) => {
  
  
  const {
    title: defaultTitle, // "CODECARAVAN"
    description: defaultDescription, // "Web development projects for coders at every stage of their learning journey"
    image,
    siteUrl,
    instagramUsername,
  } = useSiteMetadata();

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    url: `${siteUrl}${pathname || ``}`,
    image: `${image}`,
    instagramUsername,
  };

  return (
    <>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta property="image" content={seo.image} />
      <meta name="image" content={seo.image} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />

      {children}
    </>
  );
};

export default SEO;
