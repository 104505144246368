import * as React from "react";
import PropTypes from "prop-types";

import CustomHead from "../components/common/Head";

const PreviewPage = ({ isPreview, ...props }) => {
  if (isPreview === false) return "Not a preview!";

  return <p>Loading</p>;
};

export const Head = () => {
  return <CustomHead noIndex />;
};

export default PreviewPage;

PreviewPage.propTypes = {
  isPreview: PropTypes.bool,
};
